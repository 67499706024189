import React from "react";
import { Component } from "react";
import Footer from "../../components/footer/Footer";
import "./tuition.css";
import "../../App.css";

class Tuition extends Component {
  render() {
    return (
      <div className="tuition-data container-fluid">
        <div className="tuition">
          <section className="text-center font-xxxl font-weight-bold">
            <i className="fas fa-hand-holding-usd fa-lg text-primary"></i> Our
            Pricing
          </section>
        </div>
        <section className="partition"></section>
        {/**  end of header section */}
        <div className="row font-l mb-5">
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <h5 className="card-title text-center">
                  <i className="fas fa-users fa-lg text-primary"></i>
                  <span className="font-weight-bold font-xxl"> Group </span>
                  <div className="text-center pt-2 font-normal font-weight-bold">
                    5 TEAM SESSIONS PER MONTH
                  </div>
                  <div className="text-center pt-2 font-normal font-weight-bold text-primary">
                    Best for inquisitive, self-driven learners who enjoy working
                    with other students!
                  </div>
                  <section className="partition"></section>
                  <div className="text-center mt-3 font-xl font-weight-bold">
                    $100 per month, per student
                  </div>
                </h5>
                <section className="partition"></section>
                <div className="bg-primary text-center enroll-btn font-xl ">
                  <span class="tooltiptext font-normal font-weight-bold">
                    info@choosedaygeeks.com | (800) 660-8574
                  </span>
                  Enroll Now{" "}
                  <i className="fas fa-chevron-circle-right pl-1"></i>
                </div>
                <section className="partition"></section>
                <div className="card-text">
                  <ul className="txt-color font-l">
                    <li className="text-dark"></li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Share your work and discuss projects with other students
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i> 4:1
                      student to instructor ratio
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      60-minute sessions of quality learning
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Currently available for Scratch, Python & JAVA Levels 1
                      and 2, - more courses coming soon!
                    </li>
                    <section className="partition"></section>
                    <li className="text-primary font-weight-bold text-center div-2">
                      <span className="text-danger">•</span> ADD 4 MORE SESSIONS
                      PER MONTH
                    </li>
                    <li className="text-primary font-weight-bold text-center pt-3">
                      <span className="text-danger">•</span> +$50 per month, per
                      student
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <h5 className="card-title text-center">
                  <i className="fas fa-user-lock fa-lg text-primary"></i>
                  <span className="font-weight-bold font-xxl"> Private </span>
                  <div className="text-center pt-2 font-normal font-weight-bold">
                    5 PRIVATE SESSIONS PER MONTH
                  </div>
                  <div className="text-center pt-2 font-normal font-weight-bold text-primary">
                    Ideal for students who thrive in 1:1 settings, with classes
                    tailored to their unique learning needs!
                  </div>
                  <section className="partition"></section>
                  <div className="text-center mt-3 font-xl font-weight-bold">
                    $150 per month, per student
                  </div>
                </h5>
                <section className="partition"></section>
                <div className="bg-primary text-center enroll-btn font-xl ">
                  <span class="tooltiptext font-normal font-weight-bold">
                    info@choosedaygeeks.com | (800) 660-8574
                  </span>
                  Enroll Now{" "}
                  <i className="fas fa-chevron-circle-right pl-1"></i>
                </div>
                <section className="partition"></section>
                <div className="card-text">
                  <ul className="txt-color font-l">
                    <li className="text-dark"></li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i> 1:1
                      classes with a dedicated, recurring instructor
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Personalized teaching for your child’s pace and learning
                      style
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Weekly class times pre-booked for your schedule
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Reschedule up to 24 hours before class
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Receive session notes from your instructor after each
                      class
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      60-minute sessions of quality learning
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <h5 className="card-title text-center">
                  <i className="fas fa-user-plus fa-lg text-primary"></i>
                  <span className="font-weight-bold font-xxl"> Premium </span>
                  <div className="text-center pt-2 font-normal font-weight-bold">
                    8 TEAM SESSIONS PER MONTH
                  </div>
                  <div className="text-center pt-2 font-normal font-weight-bold text-primary">
                    Great for students looking to accelerate their learning or
                    enroll in both coding and mathematics!
                  </div>
                  <section className="partition"></section>
                  <div className="text-center mt-3 font-xl font-weight-bold">
                    $240 per month, per student
                  </div>
                </h5>
                <section className="partition"></section>
                <div className="bg-primary text-center enroll-btn font-xl ">
                  <span class="tooltiptext font-normal font-weight-bold">
                    info@choosedaygeeks.com | (800) 660-8574
                  </span>
                  Enroll Now{" "}
                  <i className="fas fa-chevron-circle-right pl-1"></i>
                </div>
                <section className="partition"></section>
                <div className="card-text">
                  <ul className="txt-color font-l">
                    <li className="text-dark"></li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i> 1:1
                      classes with a dedicated, recurring instructor per subject
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Faster progress with an accelerated learning plan
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Weekly class times pre-booked for your schedule
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Reschedule up to 24 hours before class
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Receive session notes from your instructor after each
                      class
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Personal recommendation letters available from CDG coding
                      academy
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      60-minute sessions of quality learning
                    </li>
                    <li className="pt-3">
                      {" "}
                      <i className="fas fa-chevron-right text-primary"></i>{" "}
                      Sibling Discount!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-align footer">
          <Footer />
        </footer>
      </div>
    );
  }
}

export default Tuition;
