import React from "react";
import { Component } from "react";
import Footer from "../../components/footer/Footer";
import "./aboutus.css";
import "../../App.css";

class AboutUs extends Component {
  render() {
    return (
          <div className="container-fluid aboutuscontent">
            <div className="aboutus">
              <section className="text-center font-xxxl font-weight-bold">
                <i className="fas fa-users text-primary"></i> About Us
              </section>
            </div>
            <section className="partition"></section>

            <div className="card-deck mb-5 container-fluid font-l">
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <div><h2>Who we are and what we do?</h2></div>
                    <ul className="font-l font-weight-normal">
                      <li className="pt-3">
                        {" "}
                        <i className="fas fa-chevron-right text-primary"></i>{" "}
                        CDG offers a full computer science curriculum for
                        students of all ages.
                      </li>
                      <li className="pt-3">
                        <i className="fas fa-chevron-right text-primary"></i> We
                        are ChooseDay Geeks working towards creating an
                        innovative and sustainable learning platform though our
                        online teaching.
                      </li>
                      <li className="pt-3">
                        {" "}
                        <i className="fas fa-chevron-right text-primary"></i>{" "}
                        Our youngest students start with our CDG Jr and Scratch
                        courses.
                      </li>
                      <li className="pt-3">
                        {" "}
                        <i className="fas fa-chevron-right text-primary"></i> Our
                        core curriculum covers the programming languages Python
                        and Java
                      </li>
                      <li className="pt-3">
                        {" "}
                        <i className="fas fa-chevron-right text-primary"></i>{" "}
                        Additional coding courses offered in web programming and
                        app development (HTML, CSS, and Javascript)
                      </li>
                      <li className="pt-3">
                        {" "}
                        <i className="fas fa-chevron-right text-primary"></i>{" "}
                        Our most advanced students can progress to gaining
                        course certifications in Agile, Project management,
                        Salesforce and other competitive programming languages
                        and courses
                      </li>
                    </ul>
                  </h5>
                </div>
              </div>
            </div>
            <section className="partition"></section>
            <div className="text-center font-xxl font-weight-bold pb-2 pt-2">
              <i className="fas fa-space-shuttle fa-lg fa-rotate-270 text-success"></i>{" "}
              Our Mission and Vision
            </div>
            <div className="card-deck text-center font-l">
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title">
                    <ul className="font-weight-normal font-l">
                      <li>
                        To inspire the world to be technically savy by
                        proactively growing exceptional diverse technology
                        talent and communities through connecting people to
                        coding and programming via digital learning.
                      </li>
                    </ul>
                     
                  </h5>
                </div>
              </div>
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title">
                    <ul className="font-weight-normal font-l">
                      <li>
                        To inspire the world to proactively grow a sustainable
                        diverse talent pool of full stack engineers for
                        tomorrow's future workforce via digital learning.
                      </li>
                    </ul>
                  </h5>
                </div>
              </div>
            </div>
            <section className="partition"></section>
            <div className="text-center pt-2 font-xxl font-weight-bold">
              Our Guiding Principles <br />
              <span className="tagline font-normal font-weight-light">
                At ChooseDay Geeks we always abide by our guiding principles
              </span>
            </div>
            <div className="card-deck m-5 text-center font-l">
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="far fa-life-ring fa-lg text-warning"></i> Safe
                    And Diverse Learning Environment
                  </h5>
                </div>
              </div>
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="fas fa-handshake  text-success fa-lg"></i>{" "}
                    Respect
                  </h5>
                </div>
              </div>
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="fas fa-balance-scale fa-lg text-info"></i>{" "}
                    Integrity
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-deck mb-5 mt-1 ml-5 mr-5 text-center font-l">
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="fas fa-people-carry fa-lg text-danger"></i>{" "}
                    Powerful Collaboration and Innovation
                  </h5>
                </div>
              </div>
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i> Superior
                    Execution
                  </h5>
                </div>
              </div>
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title  ">
                    <i className="fas fa-user-tie fa-lg text-primary pr-2"></i>
                    Servant Leadership
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-deck mb-5 ml-5 mr-5 text-center font-l">
              <div className="card myeffect">
                <div className="card-body">
                  <h5 className="card-title text-center">
                    <i className="fas fa-lightbulb fa-lg text-success"></i>{" "}
                    Curiosity
                  </h5>
                </div>
              </div>
            </div>
        <footer className="footer">
          <Footer />
        </footer>
      </div>
    );
  }
}

export default AboutUs;
