import React from "react";
import { Component } from "react";
import Footer from "../../components/footer/Footer";
import "./contactus.css";
import "../../App.css";

class ContactUs extends Component {
  render() {
    return (
      <div className="contactusdata container-fluid">
        <main className="container-fluid">
          <div className="contactus">
            <section className="text-center font-xxxl font-weight-bold">
              <i className="fas fa-file-signature text-primary"></i>Contact Us
            </section>
          </div>
          <section className="partition"></section>
          {/**  end of header section */}
          <div className="row font-l mb-5">
            <div className="col-md-4 mt-5">
              {" "}
              <div className="card myeffect h-100">
                <div className="card-body">
                  <h5 className="card-title font-l font-weight-bold">
                    <i className="fas fa-envelope-open-text fa-lg text-danger"></i>{" "}
                    Email Us
                  </h5>
                  <p className="card-text">info@choosedaygeeks.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="card myeffect h-100">
                <div className="card-body">
                  <h5 className="card-title font-l font-weight-bold">
                    <i className="fas fa-phone-volume fa-lg text-danger"></i>{" "}
                    Call Us
                  </h5>
                  <p className="card-text   ">(800) 660-8574</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="card myeffect h-100">
                <div className="card-body">
                  <h5 className="card-title font-l font-weight-bold">
                    <i className="fas fa-map-marker-alt fa-lg text-danger"></i>{" "}
                    Address
                  </h5>
                  <p className="card-text   ">
                    San Antonio, <br />
                    Texas, 78209 <br />
                    United States <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 text-center pb-5">
            
          </div>
        </main>
        <footer className="footer">
          <Footer />
        </footer>
      </div>
    );
  }
}

export default ContactUs;
