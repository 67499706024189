import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./header.css";
import "../../App.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false,
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    return (
      <Container className="pb-4">
        <Navbar
          className="header"
          fixed="top"
          collapseOnSelect
          expand="xl"
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
        >
          <Navbar.Brand className=" myeffect">
            <Link className="header-fontxl" to="/">ChooseDay Geeks</Link>
            <div className="sub-header">Striving for Innovation through Digital Learning</div>          
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="navlinks">
              <Nav className="mr-auto header-fontl">
                <Link className="myeffect" onClick={this.setNavClose} to="/">
                  <i className="fas fa-home"></i>{" "}Home
                </Link>
                <Link
                  className="myeffect"
                  onClick={this.setNavClose}
                  to="/curriculum"
                >
                  <i className="fas fa-list-alt menu-margin"></i>{" "}
                  Curriculum
                </Link>
                <Link
                  className="myeffect"
                  onClick={this.setNavClose}
                  to="/tuition"
                >
                  <i className="fas fa-list-alt menu-margin"></i>{" "}
                   Tuition
                </Link>
                <Link
                  className="myeffect"
                  onClick={this.setNavClose}
                  to="/aboutus"
                >
                  <i className="fas fa-users menu-margin"></i>{" "}
                   About Us
                </Link>

                <Link
                  className="myeffect"
                  onClick={this.setNavClose}
                  to="/contactus"
                >
                  <i className="fas fa-file-signature menu-margin"></i>{" "}
                   Contact Us
                </Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default Header;
