import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Footer from "../../components/footer/Footer";
import "./curriculum.css";

class Curriculum extends Component {
  render() {
    return (
      <Container fluid className="curriculum-data">
        <div className="curriculum">
          <section className="text-center font-xxxl font-weight-bold">
            <i className="fas fa-file-signature text-primary"></i>Curriculum
          </section>
        </div>
        <section className="partition"></section>
        {/**  end of header section */}
        <div className="text-center font-xxl  mt-2">
          <h2>Curriculum for CDG Students</h2>
          <div className="border border-info">
            <div className="row font-l mb-5">
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100 ml-2">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title pl-2">
                        CDG Coding Fundamentals
                      </h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Basics before Basics
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Level Alfa (α)
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Level Beta (β)
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Level Gamma (γ)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title   pl-2">
                        CDG Coding For Beginners
                      </h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Scratch Level 1 CDG Superstar
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Scratch Level 2 CDG Master
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100 mr-2">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title pl-2">Core Curriculum</h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Python Level 1 Graphics
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Python Level 2 Problem Solver
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Python Level 3 Data & Algorithms
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*   */}
            <div className="card myeffect h-100 mr-2 ml-2">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title pl-2">C++ Level 1 Curriculum</h5>
                </div>
              </div>
            </div>
            <div className="row font-l mb-5">
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100 ml-2">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title pl-2">Java Curriculum</h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Java Level 1
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Java Level 2
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            AP Computer Science
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100 mr-2">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title pl-2">
                        Web Programming Curriculum
                      </h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Web Programming Level 1
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Web Programming Level 2
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-5">
                <div className="card myeffect h-100 mr-2">
                  <div className="card-body">
                    <div className="bg-info">
                      <h5 className="card-title pl-2">Advanced Students</h5>
                    </div>
                    <div className="border border-info">
                      <div className="card-text boder-1 div-2">
                        <ul className="font-l text-left">
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Salesforce
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            Gamefication
                          </li>
                          <li className="pt-3">
                            <i className="fas fa-chevron-right text-primary"></i>{" "}
                            App Development
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center font-xxl  mt-2">
          <h2>Curriculum for CDG Beginners</h2>
        </div>
        <div className="row font-l mb-5">
          <div className="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title pl-2">
                    CDG Junior{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(5
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    CDG provides a fun introduction to programming for our
                    youngest students. In CDG Jr, students learn about 
                    <strong>logic, sequencing, loops, and events.</strong> They
                    also practice fundamental skills like basic algebra,
                    reading, and public speaking. Together, we 
                    <strong>
                      build games, solve puzzles, and design art
                    </strong>. <br />
                    CDG Jr sessions are <strong>25 minutes long </strong>and
                    priced differently than our other courses. Please contact us
                    for more information.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Scratch Level 1{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(8
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, we introduce computer science fundamentals
                    using Scratch's block-based programming environment. This
                    course focuses on 
                    <strong>
                      event listeners, loops, conditional statements, user
                      input, Cartesian coordinates, variables, and message
                      broadcasting.
                    </strong>{" "}
                    Read about why Scratch is the best beginning programming
                    language for kids. <br />
                    After completing this course, students are able to design
                    and program their own games in Scratch, and are ready to
                    advance to Scratch Level 2.
                  </div>
                  <details className="div-2">
                    <summary className="details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>GS1 Starting in Scratch</td>
                        </tr>
                        <tr>
                          <td>GS2 Event Listeners</td>
                        </tr>
                        <tr>
                          <td>GS3 Pen with Event Listeners</td>
                        </tr>
                        <tr>
                          <td>GS4 Loops</td>
                        </tr>
                        <tr>
                          <td>GS5 Basic Conditionals</td>
                        </tr>
                        <tr>
                          <td>GS6 Advanced Conditionals</td>
                        </tr>
                        <tr>
                          <td>Check-in #1</td>
                        </tr>
                        <tr>
                          <td>GS7 User Input</td>
                        </tr>
                        <tr>
                          <td>GS8 X & Y Coordinates</td>
                        </tr>
                        <tr>
                          <td>GS9 Variables</td>
                        </tr>
                        <tr>
                          <td>GS10 Message Broadcasting</td>
                        </tr>
                        <tr>
                          <td>Check-in #2</td>
                        </tr>
                        <tr>
                          <td>GS11 Hedgehog Race</td>
                        </tr>
                        <tr>
                          <td>GS12 Asteroid Dodge GS13 Master Project</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Scratch Level 2{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(9
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    This course is for students who have completed Scratch Level
                    1 or equivalent. In this course, we expand upon the concepts
                    introduced in Scratch Level 1 and introduce more advanced
                    ideas, including 
                    <strong>
                      nested loops, complex conditionals, cloning, string and
                      mathematical operators, lists, and functions.
                    </strong>
                    <br /> After completing this course, students have full
                    mastery of the Scratch environment, and are ready to advance
                    to Python Level 1.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>GS1 Starting in Scratch</td>
                        </tr>
                        <tr>
                          <td>GS2 Event Listeners</td>
                        </tr>
                        <tr>
                          <td>GS3 Pen with Event Listeners</td>
                        </tr>
                        <tr>
                          <td>GS4 Loops</td>
                        </tr>
                        <tr>
                          <td>GS5 Basic Conditionals</td>
                        </tr>
                        <tr>
                          <td>GS6 Advanced Conditionals</td>
                        </tr>
                        <tr>
                          <td>Check-in #1</td>
                        </tr>
                        <tr>
                          <td>GS7 User Input</td>
                        </tr>
                        <tr>
                          <td>GS8 X & Y Coordinates</td>
                        </tr>
                        <tr>
                          <td>GS9 Variables</td>
                        </tr>
                        <tr>
                          <td>GS10 Message Broadcasting</td>
                        </tr>
                        <tr>
                          <td>Check-in #2</td>
                        </tr>
                        <tr>
                          <td>GS11 Hedgehog Race</td>
                        </tr>
                        <tr>
                          <td>GS12 Asteroid Dodge GS13 Master Project</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center   mt-2 font-xxl">
          <h2>Curriculum for Python</h2>
        </div>
        <div className="row font-l mb-5">
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Python Level 1{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(10
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, students learn Python basics with the Turtle
                    programming library, with an emphasis on programmatically
                    generating graphic art. This course dives into Python
                    fundamentals: 
                    <strong>
                      loops, variables, functions, conditional statements, and
                      event listeners.
                    </strong>{" "}
                    <br />
                    By the end of this course, students have built complex
                    computer-generated art and games in Python, and are ready to
                    advance to Python Level 2.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>GrS1 Starting in Python</td>
                        </tr>
                        <tr>
                          <td>GrS2 Coordinates, Movement, and Loops</td>
                        </tr>
                        <tr>
                          <td>GrS3 Variables and Random Numbers</td>
                        </tr>
                        <tr>
                          <td>GrS4 Loops with Variables</td>
                        </tr>
                        <tr>
                          <td>GrS5 Nested Loops, Part 1</td>
                        </tr>
                        <tr>
                          <td>GrS6 Nested Loops, Part 2</td>
                        </tr>
                        <tr>
                          <td>Check In #1</td>
                        </tr>
                        <tr>
                          <td>GrS7 Functions</td>
                        </tr>
                        <tr>
                          <td>GrS8 Event Listeners</td>
                        </tr>
                        <tr>
                          <td>GrS9 Conditionals, Part 1</td>
                        </tr>
                        <tr>
                          <td>GrS10 Lists</td>
                        </tr>
                        <tr>
                          <td>GrS11 Conditionals, Part 2</td>
                        </tr>
                        <tr>
                          <td>Check In #2</td>
                        </tr>
                        <tr>
                          <td>GrS12 Space Eater</td>
                        </tr>
                        <tr>
                          <td>GrS13 Master Project</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Python Level 2{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(12
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, students dive deeper into Python
                    fundamentals with 
                    <strong>
                      loops, variables, conditional statements, and functions,{" "}
                    </strong>{" "}
                    while also introducing more advanced topics such as 
                    <strong>
                      lists, sets, dictionaries, and error checking.
                    </strong>
                    Students practice integrating these concepts in building a
                    series of complex projects. <br />
                    This course also exposes students to several real-life
                    applications for programming, including in music, ciphers,
                    and predictive algorithms.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>PS1 Variables, Strings, and Input</td>
                        </tr>
                        <tr>
                          <td>PS2 For Loops and While Loops</td>
                        </tr>
                        <tr>
                          <td>PS3 ASCII and Ciphers</td>
                        </tr>
                        <tr>
                          <td>PS4 Conditionals</td>
                        </tr>
                        <tr>
                          <td>Check In #1</td>
                        </tr>
                        <tr>
                          <td>PS5 Functions</td>
                        </tr>
                        <tr>
                          <td>PS6 Lists and Music</td>
                        </tr>
                        <tr>
                          <td>PS7 Dictionaries</td>
                        </tr>
                        <tr>
                          <td>PS8 Ciphers and Music</td>
                        </tr>
                        <tr>
                          <td>PS9 Sets</td>
                        </tr>
                        <tr>
                          <td>Check In #2</td>
                        </tr>
                        <tr>
                          <td>PS10 Todo List</td>
                        </tr>
                        <tr>
                          <td>PS11 Bank Account</td>
                        </tr>
                        <tr>
                          <td>PS12 Type Racer</td>
                        </tr>
                        <tr>
                          <td>PS13 Wordsmith</td>
                        </tr>
                        <tr>
                          <td>PS14 Master Project: Blackjack</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Python Level 3
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(14
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    This course briefly reviews Python fundamentals and focuses
                    on advanced topics in Python:
                    <strong>
                       recursion, searching and sorting algorithms, and big-O
                      notation.
                    </strong>{" "}
                    The master project for this course requires students to rely
                    on their analytical reasoning skills to recognize patterns,
                    outline solutions to problems, and systematically develop
                    algorithms that can handle rigorous testing. <br /> Students
                    who excel in this course have a strong math background
                    (Algebra 2 or higher recommended).
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>
                            DM1 Review: Variables, Strings, Input, Loops, &
                            Conditionals
                          </td>
                        </tr>
                        <tr>
                          <td>DM2 Review: Functions & Lists</td>
                        </tr>
                        <tr>
                          <td>DM3 Review: Dictionaries & Recap</td>
                        </tr>
                        <tr>
                          <td>DM4 Recursion Part 1</td>
                        </tr>
                        <tr>
                          <td>DM5 Recursion Part 2</td>
                        </tr>
                        <tr>
                          <td>
                            DM6 Introduction to Algorithms & Runtime Analysis
                          </td>
                        </tr>
                        <tr>
                          <td>DM7 Binary Search</td>
                        </tr>
                        <tr>
                          <td>DM8 Selection Sort & Insertion Sort</td>
                        </tr>
                        <tr>
                          <td>DM9 Bubble Sort</td>
                        </tr>
                        <tr>
                          <td>DM10 Merge Sort</td>
                        </tr>
                        <tr>
                          <td>DM11 Quicksort</td>
                        </tr>
                        <tr>
                          <td>DM12 File Input/Output</td>
                        </tr>
                        <tr>
                          <td>DM13 Master Problem: Conway’s Game of Life</td>
                        </tr>
                        <tr>
                          <td>DM14 Master Problem: Tic Tac Toe AI</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center   mt-2 font-xxl">
          <h2>Curriculum for Java</h2>
          <div className="font-l">
            These courses typically takes 3-6 months to complete. This depends
            on the student's dedication to homework, their previous experience,
            and the difficulty of course material. Our instructors move at the
            pace of the student, taking extra time to reinforce concepts as
            needed.
          </div>
        </div>
        <div className="row font-l mb-5">
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Java Level 1
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(10
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, students learn the fundamentals of Java,
                    focusing on{" "}
                    <strong>
                      variable types, conditionals, loops, methods, and one and
                      two dimensional arrays.
                    </strong>{" "}
                    This course also incorporates programmatically{" "}
                    <strong>generating graphic art with Java.</strong> By the
                    end of this course, students are ready to advance to Java
                    Level 2, which focuses on object-oriented programming. It is
                    highly recommended that students complete our Python Level 1
                    and/or Python Level 2 courses before enrolling in Java Level
                    1.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>JS1 Variable Types & Input/Output</td>
                        </tr>
                        <tr>
                          <td>JS2 Basic Graphics</td>
                        </tr>
                        <tr>
                          <td>JS3 Conditionals</td>
                        </tr>
                        <tr>
                          <td>JS4 Loops</td>
                        </tr>
                        <tr>
                          <td>JS5 Practice with Loops & Conditionals</td>
                        </tr>
                        <tr>
                          <td>JS6 Methods</td>
                        </tr>
                        <tr>
                          <td>JS7 Arrays</td>
                        </tr>
                        <tr>
                          <td>JS8 Two-Dimensional Arrays</td>
                        </tr>
                        <tr>
                          <td>JS9 Master Project: Battleship</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Java Level 2
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(14
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    This course focuses on object-oriented programming in Java,
                    covering{" "}
                    <strong>
                      classes & objects, subclasses, and inheritance.
                    </strong>
                    Students will have the opportunity to build console-based
                    <strong>projects and graphics-based games </strong>utilizing
                    these principles. By the end of this course, students have a
                    solid grasp of Java fundamentals and are well-prepared to
                    enrolled in an accelerated version of AP Computer Science A.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>
                            JM1 Instance Variables, Constructors, and Methods
                          </td>
                        </tr>
                        <tr>
                          <td>
                            JM2 Overloaded Constructors & Comparison Methods
                          </td>
                        </tr>
                        <tr>
                          <td>JM3 Static Variables & Methods</td>
                        </tr>
                        <tr>
                          <td>JM4 Subclasses & Inheritance</td>
                        </tr>
                        <tr>
                          <td>JM5 Subclasses & Inheritance with Graphics</td>
                        </tr>
                        <tr>
                          <td>JM6 Subclasses & Inheritance with Graphics II</td>
                        </tr>
                        <tr>
                          <td>JM7 Click Handlers</td>
                        </tr>
                        <tr>
                          <td>JM8 Key Listeners & Threading</td>
                        </tr>
                        <tr>
                          <td>JM9 Block Game</td>
                        </tr>
                        <tr>
                          <td>JM10 Master Project: Tic Tac Toe</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    AP Computer Science A
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(14
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    This course guides students through learning Java, with an
                    emphasis on <strong>object-oriented programming.</strong>{" "}
                    Students build a variety of{" "}
                    <strong>complex projects </strong>while learning about{" "}
                    <strong>objects, classes, scope, and inheritance. </strong>
                    This course also covers basic{" "}
                    <strong>searching and sorting algorithms </strong> in Java.{" "}
                    <br />
                    This course prepares students for the AP Computer Science A
                    exam, which is administered in May of each year.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>
                            APCS1 Variable Types, Input/Output, & Arithmetic
                            Operators
                          </td>
                        </tr>
                        <tr>
                          <td>APCS2 Loops & Conditionals</td>
                        </tr>
                        <tr>
                          <td>APCS3 Classes & Objects</td>
                        </tr>
                        <tr>
                          <td>
                            APCS4 Subclasses, Abstract Classes, & Interfaces
                          </td>
                        </tr>
                        <tr>
                          <td>APCS5 Standard Classes</td>
                        </tr>
                        <tr>
                          <td>APCS6 Software Design Principles</td>
                        </tr>
                        <tr>
                          <td>APCS7 Arrays and ArrayLists</td>
                        </tr>
                        <tr>
                          <td>APCS8 Recursion</td>
                        </tr>
                        <tr>
                          <td>APCS9 Basic Algorithms</td>
                        </tr>
                        <tr>
                          <td>APCS10 AP Test Prep</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center  font-xxl mt-2">
          <h2 className="cfont">Curriculum for C++ Level-1</h2>
          <div className="font-l">
            These courses typically takes 6-9 months to complete. This depends
            on the student's dedication to homework, their previous experience,
            and the difficulty of course material. Our instructors move at the
            pace of the student, taking extra time to reinforce concepts as
            needed.
          </div>
        </div>
        <div className="row font-l mb-5">
          <div className="col-md-6 mt-5">
            {" "}
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Course Structure{" "}
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(14
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, students learn the fundamentals of C++,
                    focusing on variable types, loops, control statements,
                    functions, and one- and two-dimensional arrays. These topics
                    are introduced at an accelerated pace, as students should
                    already have familiarity with these topics in Java. Then,
                    this course covers intermediate topics such as
                    object-oriented programming, memory manipulation, and
                    dynamic arrays. By the end of the course, students will have
                    a solid understanding of the essential components of C-based
                    programming languages, and be prepared to learn more
                    advanced data structures and concepts in C++. <br />
                    The prerequisite for enrolling in this course is completion
                    of Java Level 2, AP Computer Science A, or equivalent.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">Topics</h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    <Table striped bordered hover size="md">
                    <tbody>
                      <tr>
                        <td>CPPF1 Variables, Types, and Input/Output</td>
                      </tr>
                      <tr>
                        <td>CPPF2 Loops and Conditionals</td>
                      </tr>
                      <tr>
                        <td>CPPF3 Functions</td>
                      </tr>
                      <tr>
                        <td>CPPF4 Classes and Objects</td>
                      </tr>
                      <tr>
                        <td>CPPF5 Pointers</td>
                      </tr>
                      <tr>
                        <td>CPPF6 References and Parameter Passing</td>
                      </tr>
                      <tr>
                        <td>CPPF7 Arrays</td>
                      </tr>
                      <tr>
                        <td>CPPF8 Two-Dimensional Arrays</td>
                      </tr>
                      <tr>
                        <td>CPPF9 Dynamic Arrays and Memory</td>
                      </tr>
                      <tr>
                        <td>CPPF10 Master Project: Matrix Fun</td>
                      </tr>
                      <tr>
                        <td>CPPF11 Master Project: Profile Posts</td>
                      </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center font-xxl mt-2">
          <h2>Curriculum for Web</h2>
          <div className="font-l">
            These courses typically takes 6-9 months to complete. This depends
            on the student's dedication to homework, their previous experience,
            and the difficulty of course material. Our instructors move at the
            pace of the student, taking extra time to reinforce concepts as
            needed.
          </div>
        </div>
        <div className="row font-l mb-5">
          <div className="col-md-6 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Web Level 1
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(12
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    In this course, students learn 
                    <strong>HTML, CSS, and Javascript </strong>to create their
                    own websites.
                    <br /> This course is geared toward students who enjoy art
                    and design. By the end of this course, students have created
                    their web portfolios including interactive mini-projects,
                    like a photo filtering website.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td>WS1 Introduction to HTML & CSS</td>
                        </tr>
                        <tr>
                          <td>WS2 Tables, Lists, and Divs</td>
                        </tr>
                        <tr>
                          <td>WS3 Website Layout</td>
                        </tr>
                        <tr>
                          <td>WS4 Advanced Website Layout</td>
                        </tr>
                        <tr>
                          <td>WS5 Youtube Clone</td>
                        </tr>
                        <tr>
                          <td>WS6Variables and Conditionals in Javascript</td>
                        </tr>
                        <tr>
                          <td>WS7 Loops in Javascript</td>
                        </tr>
                        <tr>
                          <td>WS8 DOM Manipulation</td>
                        </tr>
                        <tr>
                          <td>WS9 Math in Javascript</td>
                        </tr>
                        <tr>
                          <td>WS10 Master Project</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="card myeffect h-100">
              <div className="card-body">
                <div className="bg-info">
                  <h5 className="card-title   pl-2">
                    Web Level 2
                    <span className=" float-right font-normal pr-4 pt-1">
                      Age(14
                      <sup>
                        <strong>+</strong>
                      </sup>
                      )
                    </span>
                  </h5>
                </div>
                <div className="border border-info">
                  <div className="card-text    boder-1 div-2">
                    This course guides students through building and deploying
                    websites and web applications. Both 
                    <strong>
                      frontend (HTML, CSS, Javascript, and JQuery) and backend
                      development (APIs and databases)
                    </strong>
                     are covered.
                    <br /> By the end of this course, students are able to
                    develop a web application that fetches news articles from an
                    API and displays a customized news feed to the user.
                  </div>
                  <details className="div-2">
                    <summary className="  details-btn bg-info">
                      Click for Topic Details
                    </summary>
                    <div>
                      <Table striped bordered hover size="md">
                      <tbody>
                        <tr>
                          <td> WA2 Tables and Navigation</td>
                        </tr>
                        <tr>
                          <td> WA3 Web Forms</td>
                        </tr>
                        <tr>
                          <td> WA4 Processing Forms with Javascript</td>
                        </tr>
                        <tr>
                          <td> WA5 Website Layout</td>
                        </tr>
                        <tr>
                          <td> WA6 Advanced CSS</td>
                        </tr>
                        <tr>
                          <td> WA7 Javascript & JQuery</td>
                        </tr>
                        <tr>
                          <td> WA8 Deployment</td>
                        </tr>
                        <tr>
                          <td> WA9 APIs and Callbacks</td>
                        </tr>
                        <tr>
                          <td> WA10 Finishing & Launching Your Pokedex</td>
                        </tr>
                        <tr>
                          <td> WA11 Databases</td>
                        </tr>
                        <tr>
                          <td> WA12 Firebase</td>
                        </tr>
                        <tr>
                          <td> WA13 Finishing & Launching Your Todo List</td>
                        </tr>
                        <tr>
                          <td> WA14 Master Project, Part 1</td>
                        </tr>
                        <tr>                          
                          <td>WA15 Master Project, Part 2</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partition"></section>
        <div className="text-center mt-2">
          <h2>Advanced CDG Courses</h2>
          <details className="div-2">
            <summary className="  details-btn bg-info">
              <span>Click for Details</span>
            </summary>
            <div className="mt-5">
              <Row className="text-center">
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Salesforce
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Gamefication
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    APP Development
                  </Button>
                </Col>
              </Row>
              <Row className="text-center mt-5 mb-4">
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Data Science
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Agile
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Project Management
                  </Button>
                </Col>
              </Row>
            </div>
          </details>
        </div>
        <section className="partition"></section>
        <div className="text-center mt-4 mb-2">
          <h2>Other CDG Offerings</h2>
          <details className="div-2">
            <summary className="  details-btn bg-info">
              <span>Click for Details</span>
            </summary>
            <div className="mt-5">
              <Row className="text-center">
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Resume Review
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Job Application
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Interview Prepration
                  </Button>
                </Col>
              </Row>
              <Row className="text-center mt-5">
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Offer Negotiation
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    F1 , L1 and H1B – Visa Application
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Furlough & Termination Negotiations
                  </Button>
                </Col>
              </Row>
              <Row className="text-center mt-5 mb-5">
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Internships, Coops & Externships
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    College Applications
                  </Button>
                </Col>
                <Col sm>
                  <Button variant="primary" size="lg" block>
                    Career Development
                  </Button>
                </Col>
              </Row>
            </div>
          </details>
        </div>
        <footer className="footer">
          <Footer />
        </footer>
      </Container>
    );
  }
}

export default Curriculum;
