import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid footer-data">
        <div className="row">
          <div className="col-lg-4 pt-4">
            {" "}
            <div>
              <Link to="/" className="footer-cdglogo font-xxxl">ChooseDay Geeks</Link>
              <div className="sub-header">
                Striving for Innovation through Digital Learning
              </div>
            </div>
          </div>
          <div className="col-lg-4 align-text-bottom pt-5">
            <div className="text-center font-small align-text-bottom copyright-txt">
              Copyright &copy; 2020 ChooseDay Geeks. All rights reserved.
            </div>
          </div>
          <div className="col-lg-4 text-md-right pt-4">
            <div className="mt-2 text-center pb-5">
              <div>
                <span className="text-primary">
                  <i className="fab fa-facebook-square fa-2x myeffect"></i>
                </span>
                <span className="text-danger ml-4 ">
                  <i className="fab fa-youtube fa-2x myeffect"></i>
                </span>
                <span className="text-primary ml-4">
                  <i className="fab fa-twitter fa-2x myeffect"></i>
                </span>
                <span className="text-danger ml-4">
                  <i className="fab fa-instagram fa-2x myeffect"></i>
                </span>
                <span className="text-primary ml-4">
                  <i className="fab fa-linkedin fa-2x myeffect"></i>
                </span>
              </div>
              <div className="pt-2 footer-contact-info">
                      info@choosedaygeeks.com | (800) 660-8574
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
