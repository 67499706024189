import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Header from "./components/header/Header";
import ContactUs from "./components/contactus/ContactUs";
import Curriculum from "./components/curriculum/Curriculum";
import Tuition from "./components/tuition/Tuition";
import AboutUs from "./components/aboutus/Aboutus";
import Home from "./components/home/Home";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <HashRouter basename="/">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/curriculum" component={Curriculum} />
          <Route path="/tuition" component={Tuition} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/aboutus" component={AboutUs} />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
