import React, { Component } from "react";
import "./home.css";
import Container from "react-bootstrap/Container";
import Footer from "../../components/footer/Footer";
import BannerImg0 from "../../img/cdg-home-img5735x635.png";

class Home extends Component {
  render() {
    return (
      <div>
        <Container fluid className="home-data">
          <main>
            <div className="row home-page-row">
              <div className="col-lg-6 w-100 home-page-text font-l">
                <h2 className="font-weight-bold">We Are There At Each Stage</h2>
                <ul>
                  <li className="pt-5">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    CDG offers a 
                    <strong>full computer science curriculum </strong>for
                    students of all ages.
                  </li>
                  <li className="pt-4">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    Our youngest students start with our CDG Jr and Scratch
                    courses.
                  </li>
                  <li className="pt-4">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    These courses use block-based programming to develop their
                    fundamental programming concepts and problem-solving skills.
                  </li>
                  <li className="pt-4">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    Our core curriculum covers the programming languages 
                    <strong>Python and Java, </strong>with additional coding
                    courses offered in web programming and app development
                    (HTML, CSS, and Javascript).
                  </li>
                  <li className="pt-4">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    Through three levels of Python, we cover 
                    <strong>
                      up to college-level topics in algorithms and data
                      structures.
                    </strong>
                  </li>
                  <li>Each course typically takes 3-6 months to complete.</li>
                  <li className="pt-4">
                    {" "}
                    <i className="fas fa-chevron-right text-info pr-1"></i>
                    Our most advanced students can progress to gaining course
                    certifications in Agile, Project management, Salesforce and
                    other  competitive programming languages and courses.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 w-100 home-page-image">
                <img
                  src={BannerImg0}
                  alt="cgd"
                  className="img-fluid side-img"
                />
              </div>

            </div>
          </main>
          <footer className="footer-align footer">
            <Footer />
          </footer>
        </Container>
      </div>
    );
  }
}

export default Home;
